import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import SitePagination from "../../components/shared/SitePagination";
import { useSelector, useDispatch } from 'react-redux';
import { MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import Config from '../../../helpers/Config';
import rootAction from '../../../stateManagment/actions/rootAction';
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';
import OrderList from '../../components/orderHistory/OrderList';
import Seo from '../../components/shared/Seo';
import LoadingScreen from '../../components/shared/LoadingScreen';
import { useGetOrdersQuery } from '../../../services/user';
import { skipToken } from '@reduxjs/toolkit/dist/query';

const OrdersHistory = () => {
    const [localizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [filter, setFilter] = useState('6')
    const [page, setPage] = useState(1);
    const [showSuccessResponse, setShowSuccessResponse] = useState(false);
    const [SuccessResponse, setSuccessResponse] = useState('');
    const loginUser = useSelector(state => state.userReducer.user);

    const { data: orders, isFetching: isOrdersFetching, error: orderFetchError } = useGetOrdersQuery({
        userId: loginUser.UserID,
        filter: filter,
        page: page
    }, {
        refetchOnMountOrArgChange: true
    });

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        // declare the data fetching function
        const initializeLocalization = async () => {
            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["OrdersHistory"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                setLocalizationLabelsArray(arryRespLocalization);
            }
        };
        // initialize component
        initializeLocalization().catch(console.error);
    }, []);

    return (
        <>
            <Seo title="Order History" description="Order History" keywords="Order History" />
            <LoadingScreen loading={isOrdersFetching} />
            <SiteBreadcrumb title="Order History" />
            <section className='order-history-page d-flex my-4 my-md-8' id='order-history'>
                <div className="container-fluid">
                    <div className="account-content-wrapper m-auto">
                        <div className="row mb-4">
                            <div className="col-12 account-title-wrapper flex-wrap position-relative mb-5">
                                <div className="form-group mb-0 mt-5 mt-md-0 order-history-select-wrappper order-1">
                                    <select name="form-control" onChange={(e) => setFilter(e.target.value)} value={filter} className='form-control custom-select order-history-select'>
                                        <option id="lbl_ordrhis_lastsixmonths" value="6">{replaceLoclizationLabel(localizationLabelsArray, "Last Six Months", "lbl_ordrhis_lastsixmonths")}</option>
                                        <option id="lbl_ordrhis_lasttwelvemonths" value="12">{replaceLoclizationLabel(localizationLabelsArray, "Last Twelve Months", "lbl_ordrhis_lasttwelvemonths")}</option>
                                        <option value="0">{new Date().getFullYear()}</option>
                                    </select>
                                </div>
                                <h2 id="lbl_ordrhis_orderhistory" className="account-page-title">{replaceLoclizationLabel(localizationLabelsArray, "Order History", "lbl_ordrhis_orderhistory")}</h2>
                                <Link to={`/${getLanguageCodeFromSession()}/my-account`} className="account-back-link link-underline" id="lbl_ordrhis_backtomyaccount">
                                    {replaceLoclizationLabel(localizationLabelsArray, "Back to My Account", "lbl_ordrhis_backtomyaccount")}
                                </Link>
                            </div>

                            <div className="col-12">
                                {orderFetchError?.message &&
                                    <div className="col-12 alert alert-danger" role="alert" aria-hidden="false" data-acsb-hidden="false"><div aria-hidden="false" data-acsb-hidden="false">{orderFetchError.message}</div></div>}
                                {showSuccessResponse &&
                                    <div className="col-12 alert alert-success" role="alert" aria-hidden="false" data-acsb-hidden="false"><div aria-hidden="false" data-acsb-hidden="false">{SuccessResponse}</div></div>}
                            </div>

                            {orders?.Orders?.length > 0 ?
                                <>
                                    <OrderList orders={orders.Orders} />
                                    {orders.PageCount > 1 &&
                                        <div className="col-12">
                                            <SitePagination
                                                totalRecords={orders.Orders.length * orders.PageCount}
                                                currentPage={page}
                                                pageSize={orders.Orders.length}
                                                setCurrentPage={handlePageChange}
                                                key={page}
                                                handleByThisComponent={false}
                                            />
                                        </div>
                                    }
                                </>
                                :
                                <div className='col-12 text-center'>
                                    <h4 id="lbl_ordrhis_noorders">{replaceLoclizationLabel(localizationLabelsArray, "No orders found", "lbl_ordrhis_noorders")}</h4>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default OrdersHistory;
