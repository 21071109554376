import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetContentAssetQuery } from '../../../services/content';

const LiveChat = () => {
    const { isChatEnabled } = useSelector(state => state.commonReducer);
    const { data: dataLiveChatUrl, isFetching: isFetchingLiveChatUrl } = useGetContentAssetQuery('contactus-chat-script');

    useEffect(() => {
        if (isChatEnabled && dataLiveChatUrl && !isFetchingLiveChatUrl) {
            const url = dataLiveChatUrl?.ContentValue;
            const script = document.createElement('script');
            script.src = url.toString();
            script.async = true;
        
            document.body.appendChild(script);
        }
    }, [isFetchingLiveChatUrl]);
}

export default LiveChat;
