import { api } from './api.ts'
import Config from '../helpers/Config.js'

type HomeBanner = {
    id: string;
    topTitle: string;
    mainTitle: string;
    bottomTitle: string;
    leftButtonText: string;
    leftButtonUrl: string;
    rightButtonText: string;
    rightButtonUrl: string;
    attachmentID: string;
    isActive: string;
    displaySeqNo: string;
    createdOn: string;
    createdBy: string;
    modifiedOn: string;
    modifiedBy: string;
    bannerImgUrl: string;
}
// Define a service using a base URL and expected endpoints
export const contentApi = api.injectEndpoints({
    endpoints: (build) => ({
        getHomeBanner: build.query<any, void>({
            query: () => {
                const params = {
                    requestParameters: {
                        recordValueJson: "[]",
                    },
                };
                return {
                    method: 'POST',
                    body: params,
                    url: Config.END_POINT_NAMES.GET_HOME_SCREEN_BANNER
                }
            },
            transformResponse: (response: any): HomeBanner[] => {
                const slides = response?.data || []
                return slides.map((contentData: any): HomeBanner => ({
                    id: contentData.BannerID,
                    topTitle: contentData.TopTitle,
                    mainTitle: contentData.MainTitle,
                    bottomTitle: contentData.BottomTitle,
                    leftButtonText: contentData.LeftButtonText,
                    leftButtonUrl: contentData.LeftButtonUrl,
                    rightButtonText: contentData.RightButtonText,
                    rightButtonUrl: contentData.RightButtonUrl,
                    attachmentID: contentData.AttachmentID,
                    isActive: contentData.IsActive,
                    displaySeqNo: contentData.DisplaySeqNo,
                    createdOn: contentData.CreatedOn,
                    createdBy: contentData.CreatedBy,
                    modifiedOn: contentData.ModifiedOn,
                    modifiedBy: contentData.ModifiedBy,
                    bannerImgUrl: contentData.BannerImgUrl,
                }))
            },
            providesTags: ['HomeBanner']
        }),
        getContentAsset: build.query<any, void>({
            query: (payload) => {
                const params = {
                    requestParameters: {
                      ContentID: payload,
                    },
                  };
                return {
                    method: 'POST',
                    body: params,
                    url: Config.END_POINT_NAMES.GET_CONTENT_BY_ID
                }
            },
            transformResponse: (response: any): any => {
                return response?.data?.[0] || []
            }
        }),
        getContentAssetCache: build.query<any, void>({
            query: (payload) => {
                const params = {
                    requestParameters: {
                      ContentID: payload,
                    },
                  };
                return {
                    method: 'POST',
                    body: params,
                    url: Config.END_POINT_NAMES.GET_CONTENT_BY_ID
                }
            },
            transformResponse: (response: any): any => {
                return response?.data?.[0] || []
            }
        })
    })
})

export const {
    useGetHomeBannerQuery,
    useGetContentAssetQuery,
    useGetContentAssetCacheQuery
} = contentApi
