import { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../stateManagment/reduxStore";
import { replaceLoclizationLabel } from "../../../helpers/CommonHelper";
import ContentLoader from "react-content-loader";
import { formatPhoneNumber } from "react-phone-number-input";
import rootAction from "../../../stateManagment/actions/rootAction";
import { Link } from "react-router-dom";
import { PaymentMethod } from "./PaymentMethod";
import { ShippingMethods } from "./ShippingMethods";
import { FormGroup, Input, Label, FormFeedback } from "reactstrap";
import { useGetContentAssetQuery } from "../../../services/content";
import { useGetPaymentMethodsQuery } from "../../../services/checkout";
import { useGetAddressesQuery } from "../../../services/user";
import { ToggleContext } from "../../../ToggleProvider";

export const AddressSummary = ({ address, email, type, subTitle, setEditAddress, hasCreditCardPaymentMethod, isPurchaseOrderNumberRequired }) => {
    const LocalizationLabelsArray = [];
    const dispatch = useAppDispatch();    
    const { editableBilling, editableShipping, shipToLocations, shipToLocationEnabled, isDedicatedAllotmentAddressesEnabled, isAddAllotmentAddressEnabled, allotmentAddresses, selectedPaymentMethod, isPurchaseOrderValid, customerPurchaseOrderError } = useSelector((state) => state.checkout);
    const { isToggled, setIsToggled } = useContext(ToggleContext);
    const handleEditAddress = (e) => {
        e.preventDefault();
        setEditAddress({
            type,
            open: true
        });
        if (type === 'shipping') {
            dispatch(rootAction.checkoutActions.setShippingAddress(address));
        } else {
            dispatch(rootAction.checkoutActions.setBillingAddress(address));
        }
    };
    const handleAddAllotmentAddress = (e) => {
        e.preventDefault();
        setEditAddress({
            type: 'shipping',
            open: true,
            isAddNewAllotmentShippingAddress: true
        });
    };
    const customerPurchaseOrder = useSelector((state) => state.checkout.customerPurchaseOrder);
    useGetContentAssetQuery('payment-credit-amount');
    useGetPaymentMethodsQuery(null);
    const [allowEdit, setAllowEdit] = useState(false);
    
    const { isFetching: isCustomerAddressesFetching } = useGetAddressesQuery({
        cartProducts: []
    });
    const { user: { CreditBalance: creditBalance, PayrollDeduction: payrollDeduction } } = useSelector((state) => state.userReducer);
    const { orderTotal } = useSelector((state) => state.cartReducer);

    const getEditAddress = (type) => {
        if (type == 'shipping') {
            return editableShipping || (shipToLocationEnabled && shipToLocations.length > 1)
        }

        if (!hasCreditCardPaymentMethod || !editableBilling)
            return false;

        if (selectedPaymentMethod === '' || selectedPaymentMethod === 'CREDIT_CARD')
            return true;

        return creditBalance + payrollDeduction?.AvailablePDBalance < orderTotal;
    }

    useEffect(() => {
        setAllowEdit(getEditAddress(type));
    }, [editableBilling, editableShipping, shipToLocations, shipToLocationEnabled, isDedicatedAllotmentAddressesEnabled, isAddAllotmentAddressEnabled, allotmentAddresses, selectedPaymentMethod])

    const updatePurchaseOrderNumber = (e) => {
        e.preventDefault();
        dispatch(rootAction.checkoutActions.setCustomerPurchaseOrder(e.target.value));
        if (isPurchaseOrderNumberRequired) {
            dispatch(rootAction.checkoutActions.setCustomerPurchaseOrderError(""));
            var hasText = !!e.target.value && !!e.target.value.trim();
            if (!hasText) {
                dispatch(rootAction.checkoutActions.setCustomerPurchaseOrderError("Please fill out this field."));
            }
            dispatch(rootAction.checkoutActions.setIsPurchaseOrderValid(hasText));
        }
    };

    return (
        <>
            {<div className='d-flex align-items-center mb-0 mb-md-2'>
                <span className='label'>
                    {LocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(LocalizationLabelsArray, subTitle, "lbl_check_shippingaddress")
                        :
                        subTitle
                    }
                </span>
                {isToggled && isDedicatedAllotmentAddressesEnabled && isAddAllotmentAddressEnabled && type === 'shipping' ?
                    <>
                        <span className='divider'></span>
                        <Link className='btn-link link-underline edit-button' to='#' onClick={handleAddAllotmentAddress}>
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Add", "lbl_check_add")
                                :
                                "Add"
                            }
                        </Link>
                    </> : null}
                {allowEdit && type !== 'po' ?
                    <>
                        <span className='divider'></span>
                        <Link className='btn-link link-underline edit-button' to='#' onClick={handleEditAddress}>
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Edit", "lbl_check_edit")
                                :
                                "Edit"
                            }
                        </Link>
                    </> : null}
            </div>}
            {type !== 'po' && (address && address.address1 ?
                <div className="customer-summary">
                    {isToggled && isDedicatedAllotmentAddressesEnabled && address.allotmentAddressLabel && <p>{address.allotmentAddressLabel}</p>}
                    <p>{address.fullName}</p>
                    <p>{address.address1}</p>
                    {address.address2.length > 0 && <p>{address.address2}</p>}
                    <p>{address.city}, {address.stateCode} {address.postalCode}</p>
                    <p>{address.countryCode === 'US' || address.countryCode === 'USA' ? 'United States' : ''}</p>
                </div> :
                <div className='mb-4'>
                    <ContentLoader
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        viewBox="0 0 280 105"
                        height={112}
                    >
                        <rect x="0" y="0" width="100" height="22" />
                        <rect x="0" y="28" width="140" height="22" />
                        <rect x="0" y="56" width="150" height="22" />
                        <rect x="0" y="84" width="125" height="22" />
                    </ContentLoader>
                    {shipToLocationEnabled == 0 && shipToLocations?.length ?
                        <Label className="text-danger">No Shipping Location configured for this account. Please contact us for assistance.</Label>
                        : <></>
                    }
                </div>)
            }
            {type === 'shipping' &&
                <>
                    <p className='label mb-0 mb-md-2'>
                        {LocalizationLabelsArray.length > 0 ?
                            replaceLoclizationLabel(LocalizationLabelsArray, "Order contact information", "lbl_check_ordercontactinformation")
                            :
                            "Order contact information"
                        }
                    </p>
                    {email || address || address.phone ?
                        <div className="customer-summary">
                            {email ?
                                <p>{email}</p>
                                :
                                null
                            }
                            {address?.phone ?
                                <p>{formatPhoneNumber('+1' + address?.phone)}</p>
                                :
                                null
                            }
                        </div>
                        :

                        <ContentLoader
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                            viewBox="0 0 280 50"
                            height={32}
                        >
                            <rect x="0" y="0" width="200" height="100" />
                        </ContentLoader>
                    }
                </>
            }

            {type === 'po' &&
                <div className="customer-summary">
                    <FormGroup>
                        <Label for='purchaseOrder'>
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Purchase Order #", "lbl_check_city")
                                :
                                "Purchase Order #"
                            }
                            {isPurchaseOrderNumberRequired ?
                                <>
                                </>
                                :
                                <>
                                    <br />
                                    <small>If you require a purchase order (po) number when placing orders, please enter it below.</small>
                                </>
                            }

                        </Label>
                        <Input type='text' name='purchaseOrder' id='purchaseOrder' placeholder='Purchase Order #' maxLength='255'
                            invalid={isPurchaseOrderNumberRequired && !isPurchaseOrderValid}
                            value={customerPurchaseOrder}
                            onChange={updatePurchaseOrderNumber}
                        />
                        <FormFeedback type='invalid'>{customerPurchaseOrderError}</FormFeedback>
                    </FormGroup>
                </div>
            }
        </>
    )
};
export const AddressValidateSummary = ({ address, type, title }) => {
    const LocalizationLabelsArray = [];

    return (
        <div className={` ${type === 'shipping' ? 'shipping-summary' : 'billing-summary'}`}>
            {(title) &&
                <p className="mb-2">
                    {LocalizationLabelsArray.length > 0 ?
                        replaceLoclizationLabel(LocalizationLabelsArray, title, "lbl_check_shipping")
                        :
                        title
                    }
                </p>}
            {address ?
                <div className="customer-summary">
                    {address.fullName}<br />
                    {address.address1}<br />
                    {address.address2.length > 0 && <>{address.address2}<br /></>}
                    {address.city}, {address.stateCode} {address.postalCode}<br />
                    {address.countryCode === 'US' || address.countryCode === 'USA' ? 'United States' : ''}
                </div> :
                <div>
                    <ContentLoader
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        viewBox="0 0 280 105"
                        height={112}
                    >
                        <rect x="0" y="0" width="100" height="22" />
                        <rect x="0" y="28" width="140" height="22" />
                        <rect x="0" y="56" width="150" height="22" />
                        <rect x="0" y="84" width="125" height="22" />
                    </ContentLoader>
                </div>}
        </div>
    )
}