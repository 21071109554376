import { api } from './api.ts'
import Config from '../helpers/Config'
import { AxiosResponse } from 'axios'
import rootAction from '../stateManagment/actions/rootAction';

export type Address = {
    id?: string,
    address1: string,
    address2: string,
    city: string,
    countryCode: string,
    email?: string,
    fullName?: string,
    phone: string,
    postalCode: string,
    stateCode: string,
    firstName?: string,
    lastName?: string,
    locationId?: string,
    axLocationId?: string,
    c_addressType: string,
    c_isSmartyValidated: boolean,
    allotmentAddressLabel: string,
    allotmentAddressId: number
}
type CustomerAddresses = {
    billingAddress: Address,
    shippingAddress: Address,
    allAddresses: Address[], 
    shipToLocations: Address[]
}

// Define a service using a base URL and expected endpoints
export const userApi = api.injectEndpoints({
    endpoints: (build) => ({
        me: build.query<any, any>({
            query: () => {
                return {
                    url: Config.END_POINT_NAMES.ME,
                    method: 'POST',
                    body: {
                        requestParameters: {
                            recordValueJson: "[]",
                        }
                    },
                }
            },
            transformResponse: (response: AxiosResponse) => {
                return response.data?.[0]
            },
            providesTags: ['User']
        }),
        editAddress: build.mutation<any, any>({
            query: (payload: any) => {
                const params = {
                    requestParameters: {
                        addressJson: JSON.stringify(payload.address),
                        isNew: payload.isNew,
                        recordValueJson: "[]",
                    },
                };
                return {
                    url: Config.END_POINT_NAMES.EDIT_ADDRESS,
                    method: 'POST',
                    body: params,
                };
            },
            invalidatesTags: ['CustomerAddresses']
        }),
        updateAllotmentPackage: build.mutation<any, any>({
            query: (payload: any) => {
                const params = {
                    requestParameters: {
                        packageId: payload.id,
                        options: payload.options
                    }
                };
                return {
                    url: Config.END_POINT_NAMES.UPDATE_ALLOTMENT,
                    method: 'POST',
                    body: params,
                };
            },
            invalidatesTags: ['Cart']
        }),
        validateAddress: build.query<any, any>({
            query: (payload: any) => {
                const params = {
                    requestParameters: {
                        Street: payload.address1,
                        City: payload.city,
                        State: payload.stateCode,
                        Zipcode: payload.postalCode,
                        MaxCandidates: 1,
                    }
                };
                return {
                    url: Config.END_POINT_NAMES.VALIDATE_ADDRESS,
                    method: 'POST',
                    body: params,
                };
            },
            transformResponse: (response: any) => {
                return response.data;
            }
        }),
        removeAddress: build.mutation<any, any>({
            query: (id: string) => {
                const params = {
                    requestParameters: {
                        addressId: id,
                        recordValueJson: "[]",
                    },
                };
                return {
                    url: Config.END_POINT_NAMES.REMOVE_ADDRESS,
                    method: 'POST',
                    body: params,
                };
            },
            invalidatesTags: ['CustomerAddresses']
        }),
        getAddresses: build.query<CustomerAddresses, any>({
            query: (payload: any) => {
                const params = {
                    requestParameters: {
                        cartJsonData: JSON.stringify(payload?.cartProducts),
                        recordValueJson: "[]",
                    },
                };
                return {
                    url: Config.END_POINT_NAMES.GET_CUSTOMER_ADDRESS,
                    method: 'POST',
                    body: params,
                }
            },
            transformResponse: (response: AxiosResponse, meta, arg): CustomerAddresses => {
                const data = response.data || {};
                return {
                    shippingAddress: {
                        id: data.ShippingAddress.address_id,
                        address1: data.ShippingAddress.address1,
                        address2: data.ShippingAddress.address2,
                        city: data.ShippingAddress.city,
                        countryCode: data.ShippingAddress.country_code,
                        postalCode: data.ShippingAddress.postal_code,
                        phone: data.ShippingAddress.phone,
                        email: data.ShippingAddress.email,
                        stateCode: data.ShippingAddress.state_code,
                        fullName: data.ShippingAddress.full_name,
                        firstName: data.ShippingAddress.first_name,
                        lastName: data.ShippingAddress.last_name,
                        c_addressType: data.ShippingAddress.c_axAddressType,
                        c_isSmartyValidated: data.ShippingAddress.c_isSmartyValidated,
                        allotmentAddressLabel: data.ShippingAddress.allotmentAddressLabel,
                        allotmentAddressId: data.ShippingAddress.allotmentAddressId
                    },
                    billingAddress: {
                        id: data.BillingAddress.address_id,
                        address1: data.BillingAddress.address1,
                        address2: data.BillingAddress.address2,
                        city: data.BillingAddress.city,
                        countryCode: data.BillingAddress.country_code,
                        postalCode: data.BillingAddress.postal_code,
                        phone: data.BillingAddress.phone,
                        email: data.BillingAddress.email,
                        stateCode: data.BillingAddress.state_code,
                        fullName: data.BillingAddress.full_name,
                        firstName: data.BillingAddress.first_name,
                        lastName: data.BillingAddress.last_name,
                        c_addressType: data.BillingAddress.c_axAddressType,
                        c_isSmartyValidated: data.BillingAddress.c_isSmartyValidated,
                        allotmentAddressLabel: '',
                        allotmentAddressId: 0
                    },
                    allAddresses: data.AllAddresses.map((o: any) => {
                        return {
                            id: o.address_id,
                            address1: o.address1,
                            address2: o.address2,
                            city: o.city,
                            countryCode: o.country_code,
                            postalCode: o.postal_code,
                            phone: o.phone,
                            email: o.email,
                            stateCode: o.state_code,
                            fullName: o.full_name,
                            c_addressType: o.c_axAddressType,
                            c_isSmartyValidated: o.c_isSmartyValidated
                        };
                    }),
                    shipToLocations: data.ShipToLocations.map((o: any) => {
                        return {
                            id: o.address_id,
                            address1: o.address1,
                            address2: o.address2,
                            city: o.city,
                            countryCode: o.country_code,
                            postalCode: o.postal_code,
                            phone: o.phone,
                            email: o.email,
                            stateCode: o.state_code,
                            fullName: o.full_name,
                            c_addressType: o.c_axAddressType,
                            c_isSmartyValidated: o.c_isSmartyValidated
                        };
                    })
                }
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data: addressData } = await queryFulfilled;
                    dispatch(rootAction.userAction.setUserAddress({
                        billing: addressData.billingAddress,
                        shipping: addressData.shippingAddress
                    }));
                } catch (error) { }
            },
            providesTags: ['CustomerAddresses']
        }),
        getEmployeeCredit: build.query<any, any>({
            query: (payload: any) => {
                const params = {
                    requestParameters: {
                        UserId: payload,
                    },
                };
                return {
                    url: Config.END_POINT_NAMES.GET_EMPLOYEE_CREDIT_BALANCE,
                    method: 'POST',
                    body: params,
                }
            },
            transformResponse: (response: AxiosResponse, meta, arg): any => {
                const data = response.data || {}
                return data.CreditBalance
            }
        }),
        getOrders: build.query<any, any>({
            query: (payload: any) => {
                const params = {
                    requestParameters: {
                        ...payload
                    },
                };
                return {
                    url: Config.END_POINT_NAMES.GET_CUSTOMER_ORDERS,
                    method: 'POST',
                    body: params,
                }
            },
            transformResponse: (response: AxiosResponse, meta, arg): any => {
                const data = response.data || {}
                return data
            }
        }),
        updateAddresses: build.mutation<any, any>({
            query: (payload: any) => {
                const params = {
                    requestParameters: {
                        BillingAddress: {
                            address_id: payload.billingAddress.id,
                            address1: payload.billingAddress.address1,
                            address2: payload.billingAddress.address2,
                            city: payload.billingAddress.city,
                            postal_code: payload.billingAddress.postalCode,
                            country_code: payload.billingAddress.countryCode,
                            state_code: payload.billingAddress.stateCode,
                            first_name: payload.billingAddress.firstName,
                            last_name: payload.billingAddress.lastName,
                            phone: payload.billingAddress.phone,
                            c_axAddressType: 'B',
                            allotmentAddressLabel: payload.billingAddress.allotmentAddressLabel,
                            allotmentAddressId: payload.billingAddress.allotmentAddressId
                        },
                        ShippingAddress: {
                            address_id: payload.shippingAddress.id,
                            address1: payload.shippingAddress.address1,
                            address2: payload.shippingAddress.address2,
                            city: payload.shippingAddress.city,
                            postal_code: payload.shippingAddress.postalCode,
                            country_code: payload.shippingAddress.countryCode,
                            state_code: payload.shippingAddress.stateCode,
                            first_name: payload.shippingAddress.firstName,
                            last_name: payload.shippingAddress.lastName,
                            phone: payload.shippingAddress.phone,
                            c_axAddressType: 'S',
                            allotmentAddressLabel: payload.shippingAddress.allotmentAddressLabel,
                            allotmentAddressId: payload.shippingAddress.allotmentAddressId

                        },
                        Email: payload.email,
                        LocationNumber: payload.locationNumber
                    },
                };
                return {
                    url: Config.END_POINT_NAMES.UPDATE_PROFILE,
                    method: 'POST',
                    body: params,
                }
            },
            invalidatesTags: ['CustomerAddresses']
        }),
        saveAllotmentShippingAddress: build.mutation<any, any>({
            query: (payload: any) => {
                return {
                    url: Config.END_POINT_NAMES.SAVE_ALLOTMENT_SHIPPING_ADDRESS,
                    method: 'POST',
                    body: payload
                }
            },
            invalidatesTags: ['CustomerAddresses']
        }),
        updateProfile: build.mutation<any, any>({
            query: (payload: any) => {
                return {
                    url: Config.END_POINT_NAMES.EDIT_PROFILE,
                    method: 'POST',
                    body: payload,
                }
            },
            invalidatesTags: ['User']
        }),
    })
})

export const { 
    useUpdateAllotmentPackageMutation,
    useGetAddressesQuery, 
    useEditAddressMutation, 
    useLazyValidateAddressQuery, 
    useRemoveAddressMutation, 
    useGetEmployeeCreditQuery, 
    useMeQuery, 
    useGetOrdersQuery, 
    useUpdateAddressesMutation, 
    useSaveAllotmentShippingAddressMutation,
    useUpdateProfileMutation 
} = userApi
