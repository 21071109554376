import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert,Card, CardHeader, CardTitle, CardSubtitle, CardBody } from 'reactstrap'
import { getLanguageCodeFromSession, replaceLoclizationLabel } from '../../../helpers/CommonHelper';

const SSOCustomerCard = (props) => {
    const { ssoLoginEp, ssoLogoutEp, ssoCompany, showExtError, loginCustomWording, ssoButtonText} = props;

    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

    const newCustomerBulletPoint = [
        {title: 'Use your existing single sign on (SSO) account', langkey: 'lbl_login_bulletponit1'},
        {title: 'Manage your account using your existing system', langkey: 'lbl_login_bulletponit3'},
    ];

    return (
        <Card color="light" className="border-0 card--has-bg-color h-100">   
            {loginCustomWording?.length > 0 ?
                <>
                    <CardHeader className='border-0 card-header--no-divisor'>
                        <Alert color="danger" className={`${showExtError ? 'd-block' : 'd-none'}`}>
                            "Oops! The external login failed. Please contact your system administrator."                                
                        </Alert>
                        <div className="clearfix" dangerouslySetInnerHTML={{ __html: loginCustomWording }}></div>
                    </CardHeader>
                    <CardBody className='d-flex flex-column'>
                        <Link to={ssoLoginEp} className="btn btn-primary mt-auto mx-auto" id="lbl_login_createacnt_2">
                            {ssoButtonText}
                        </Link>
                    </CardBody>
                </>
                :
                <>
                    <CardHeader className='border-0 card-header--no-divisor'>
                        <Alert color="danger" className={`${showExtError ? 'd-block' : 'd-none'}`}>
                            "Oops! The external login failed. Please contact your system administrator."                                
                        </Alert>
                        <CardTitle tag="h2">
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, " New Customer", "lbl_login_newcustomer")
                                :
                                "Corporate Account Login"
                            }
                        </CardTitle>
                    </CardHeader>
                    <CardBody className='d-flex flex-column'>               
                        <h4 className='mb-5'>
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Why Create an Account?", "lbl_login_whycreateaccount")
                                :
                                "Login with your "+ ssoCompany + " account."
                            }
                        </h4>
                        <ul className='pl-5'>
                        {newCustomerBulletPoint.map((e, i) => (
                            <li key={i}>
                                {LocalizationLabelsArray.length > 0 ?
                                    replaceLoclizationLabel(LocalizationLabelsArray, e.title, e.langkey)
                                    :
                                    e.title
                                }
                            </li>
                        ))}
                        </ul>
                        <Link to={ssoLoginEp} className="btn btn-primary mt-auto" id="lbl_login_createacnt_2">
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Create an Account", "lbl_login_createacnt_2")
                                :
                                "Login with your "+ ssoCompany + " account"
                            }
                        </Link>
                    </CardBody>
                </>
            }
        </Card>
    );
}

export default SSOCustomerCard;
