import { Outlet, ScrollRestoration } from "react-router-dom";
import ScrollToTop from "../shared/ScrollToTop";
import Seo from "../shared/Seo";
import Navbar from "./Navbar";
import Footer from "./Footer";
import LiveChat from "./LiveChat";
import SizingBoard from "./SizingBoard";
import { useContext } from "react";
import { ToggleContext } from "../../../ToggleProvider";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

export default function RootLayout() {
    const { isToggled, setIsToggled } = useContext(ToggleContext);
    const loginUser = useSelector(state => state.userReducer.user);
    const { pathname } = useLocation();
    return (
        <>
            <Seo />
            <Navbar/>
            {isToggled && loginUser.UserID && pathname !== '/login' &&
                <SizingBoard />
            }
            <Outlet />
            <Footer />
            {loginUser.UserID &&
                <LiveChat />
            }
            <ScrollRestoration />
        </>
    );
}