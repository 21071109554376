import { Fragment, useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { useSelector } from 'react-redux';
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';
import { formatPhoneNumber } from 'react-phone-number-input'
import Seo from '../../components/shared/Seo';
import { useGetAddressesQuery, useGetOrdersQuery, useMeQuery } from '../../../services/user';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import LoadingScreen from '../../components/shared/LoadingScreen';
import { PAYMENT_INSTRUMENTS } from '../../../helpers/Constants';
import { formatCurrency } from '../../../helpers/ConversionHelper';
import { useGetPaymentMethodsQuery } from '../../../services/checkout';
import rootAction from '../../../stateManagment/actions/rootAction';
import { useAppDispatch } from '../../../stateManagment/reduxStore';
import { Label } from 'reactstrap';
import { ToggleContext } from '../../../ToggleProvider';

const MyAccount = () => {
    const { isToggled, setIsToggled } = useContext(ToggleContext);
    useMeQuery(null, {
        refetchOnMountOrArgChange: true,
    });
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

    const loginUser = useSelector(state => state.userReducer.user);
    const { data: customerAddresses, isLoading: isCustomerAddressesLoading, isFetching: isCustomerAddressesFetching } = useGetAddressesQuery({
        cartProducts: [],
        userId: loginUser?.UserID
    }, {
        refetchOnMountOrArgChange: true,
    });
    const { data: orders, isFetching: isOrdersFetching } = useGetOrdersQuery( {
        userId: loginUser?.UserID,
        filter: "6",
        page: 1
    }, {
        refetchOnMountOrArgChange: true
    });
    const currentCart = useSelector(state => state.cartReducer);

    const { data: paymentMethods, isFetching: isPaymentMethodFetching } = useGetPaymentMethodsQuery(null);
    const isPersonalInfoModificationAllowed = useSelector(state => state.commonReducer.isPersonalInfoModificationAllowed);

    useEffect(() => {
        if (!isPaymentMethodFetching && currentCart && loginUser) {
            dispatch(rootAction.userAction.isOnAccountCapable({
                paymentMethods,
                isOnAccount: loginUser?.IsOnAccount,
                isToggled
            }));
        }
    }, [isPaymentMethodFetching, currentCart, loginUser]);

    useEffect(() => {
        if (loginUser == null || loginUser == undefined || loginUser.UserID == undefined && loginUser.UserID < 1) {
            navigate('/' + getLanguageCodeFromSession() + '/');
        }
    }, [loginUser])

    useEffect(() => {

        // declare the data fetching function
        const dataOperationInUseEffect = async () => {
            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["UpdateProfile"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                setLocalizationLabelsArray(arryRespLocalization);
            }

        }
        // call the function
        dataOperationInUseEffect().catch(console.error);
    }, [])

    return (
        <>
            <Seo title="My Account" description="My Account" keywords="My Account" />
            <LoadingScreen loading={isCustomerAddressesLoading || isCustomerAddressesFetching || isOrdersFetching} />
            <SiteBreadcrumb title="My Account" />

            <section className="my-account-page d-flex my-4 my-md-8" id="my-account">
                <div className="container">
                    <h2 className="account-page-title mb-5">My Account</h2>
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div data-private="lipsum" className="card border-0 card--has-bg-color p-0 mb-5">
                                <div className="card-header d-flex align-items-center border-0">
                                    <h2 className="card-title">Profile</h2>
                                    {isPersonalInfoModificationAllowed&&
                                    <Link to={`/${getLanguageCodeFromSession()}/edit-profile`} className="ml-4 pl-4 card-header-link">
                                        {LocalizationLabelsArray.length > 0 ?
                                            replaceLoclizationLabel(LocalizationLabelsArray, "Edit Profile", "lbl_update_profile")
                                            :
                                            "Edit Profile"
                                        }
                                    </Link>
                                    }
                                </div>
                                <div className="card-body profile-card-body">
                                    <dl>
                                        <dt className="label">Employee ID</dt>
                                        <dd>{loginUser.EmployeeID}</dd>
                                    </dl>
                                    <dl>
                                        <dt className="label">First Name</dt>
                                        <dd>{loginUser.FirstName}</dd>
                                    </dl>
                                    <dl>
                                        <dt className="label">Last Name</dt>
                                        <dd>{loginUser.LastName}</dd>
                                    </dl>
                                    <dl>
                                        <dt className="label">Email Address</dt>
                                        <dd className="text-overflow-ellipsis">{loginUser.EmailAddress}</dd>
                                        <dd className="d-none">{loginUser.UserID}</dd>
                                    </dl>
                                    <dl>
                                        <dt className="label">Phone Number</dt>
                                        <dd>{formatPhoneNumber("+1" + loginUser.PhoneNo)}</dd>
                                    </dl>
                                </div>
                            </div>
                            <div className="card border-0 card--has-bg-color p-0 mb-5">
                                <div className="card-header d-flex align-items-center border-0">
                                    <h2 className="card-title">Password</h2>
                                    {isPersonalInfoModificationAllowed&&
                                    <Link to={`/${getLanguageCodeFromSession()}/change-password`} className="ml-4 pl-4 card-header-link">
                                        {LocalizationLabelsArray.length > 0 ?
                                            replaceLoclizationLabel(LocalizationLabelsArray, "Edit Password", "lbl_edit_password")
                                            :
                                            "Edit Password"
                                        }
                                    </Link>
                                    }
                                </div>
                                <div className="card-body">
                                    <dl>
                                        <dt className="label">Password</dt>
                                        <dd>********</dd>
                                    </dl>
                                </div>
                            </div>
                            <div data-private="lipsum" className="card border-0 card--has-bg-color p-0 mb-5">
                                <div className="card-header d-flex align-items-center border-0">
                                    <h2 className="card-title">Address Book</h2>
                                    {/* <Link to={`/${getLanguageCodeFromSession()}/address-book`} className="ml-4 pl-4 card-header-link">
                                        {LocalizationLabelsArray.length > 0 ?
                                            replaceLoclizationLabel(LocalizationLabelsArray, "View All Addresses", "lbl_view_all_addresses")
                                            :
                                            "View All Addresses"
                                        }
                                    </Link> */}
                                </div>
                                <div className="card-body profile-card-body">
                                    <div className='row'>
                                        <div className="col-12 col-md-6 mb-5 mb-md-0">
                                            <div className="label mb-3">Billing Address</div>
                                            <div className="dashboard-info d-none">Home</div>
                                            {customerAddresses?.billingAddress.id ?
                                                <>
                                                    <div>{customerAddresses?.billingAddress.fullName}</div>
                                                    <div>{customerAddresses?.billingAddress.address1}</div>
                                                    {customerAddresses?.billingAddress.address2 ?
                                                        <div>{customerAddresses?.billingAddress.address2}</div>
                                                        :
                                                        <></>
                                                    }
                                                    <div>{customerAddresses?.billingAddress.city}, {customerAddresses?.billingAddress.stateCode} {customerAddresses?.billingAddress.postalCode} </div>
                                                    <div>{formatPhoneNumber("+1" + customerAddresses?.billingAddress.phone)}</div>
                                                </>
                                                :
                                                <></>
                                            }
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="label mb-3">Shipping Address</div>
                                            <div className="dashboard-info d-none">Home</div>
                                            {customerAddresses?.shippingAddress.id ?
                                                <>
                                                    <div>{customerAddresses?.shippingAddress.fullName}</div>
                                                    <div>{customerAddresses?.shippingAddress.address1}</div>
                                                    {customerAddresses?.shippingAddress.address2 ?
                                                        <div>{customerAddresses?.shippingAddress.address2}</div>
                                                        :
                                                        <></>
                                                    }
                                                    <div>{customerAddresses?.shippingAddress.city}, {customerAddresses?.shippingAddress.stateCode} {customerAddresses?.shippingAddress.postalCode} </div>
                                                    <div>{formatPhoneNumber("+1" + customerAddresses?.shippingAddress.phone)}</div>
                                                </>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="border-0 card-footer">
                                    {isPersonalInfoModificationAllowed&&<Link to="/update-profile" className="btn btn-primary mb-2">Update Addresses</Link>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card border-0 card--has-bg-color p-0 mb-5">
                                <div className="card-header d-flex align-items-center border-0">
                                    <h2 className="card-title">Payment</h2>
                                </div>
                                <div data-private="" className="card-body card-info-group">
                                    <div className="m-0"></div>
                                    {!isPaymentMethodFetching && (() => {
                                        const creditCardPaymentMethod = paymentMethods?.applicable_payment_methods?.find(pm => pm.id === 'CREDIT_CARD');
                                        const employeeCreditPaymentMethod = paymentMethods?.applicable_payment_methods?.find(pm => pm.id === 'UAB_EMPLOYEE_CREDITS');
                                        const pdPaymentMethod = paymentMethods?.applicable_payment_methods?.find(pm => pm.id === 'UAB_PAYROLL_DEDUCTION');
                                        const onAccountPaymentMethod = paymentMethods?.applicable_payment_methods?.find(pm => pm.id === 'ON_ACCOUNT');
                                        let paymentComponent = null;
                                        let afterCreditCardText = ".";
                                        if (paymentMethods?.applicable_payment_methods?.length === 0 || (onAccountPaymentMethod && !loginUser.IsOnAccountCapable && paymentMethods?.applicable_payment_methods?.length === 1)) {
                                            paymentComponent = (
                                                <Label className="text-danger text-uppercase">
                                                    No payment methods available. Please contact us for assistance.
                                                </Label>
                                            );
                                        }
                                        else if (loginUser.IsOnAccountCapable) {
                                            paymentComponent = (
                                                <Label>
                                                    {`${isToggled ? "Allotment" : 'On Account'} Payment will be available at checkout.`}
                                                </Label>
                                            )
                                        }
                                        else if (loginUser.CreditEnabled && employeeCreditPaymentMethod) {
                                            paymentComponent = (
                                                <div className="form-group">
                                                    <label>Credit Balance</label>
                                                    <input className="form-control" type="text" value={"$ " + (loginUser.CreditBalance || 0)?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} readOnly />
                                                </div>
                                            )
                                            afterCreditCardText = ", after Credit Balance has been exhausted.";
                                        }
                                        else if (loginUser.PayrollDeductionEnabled && pdPaymentMethod) {
                                            paymentComponent = (
                                                <div className="form-group">
                                                    <label>PD Balance</label>
                                                    <input className="form-control" type="text" value={"$ " + (loginUser.PayrollDeduction.AvailablePDBalance || 0)?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} readOnly />
                                                </div>
                                            )
                                            afterCreditCardText = ", after Payroll Deduction Balance has been exhausted.";
                                        }

                                        let creditcardComponent = creditCardPaymentMethod && !loginUser.IsOnAccountCapable && (
                                            <Label>
                                                Credit Card Payment will be available at checkout{afterCreditCardText}
                                            </Label>
                                        );

                                        if (creditCardPaymentMethod && loginUser.PayrollDeductionEnabled && !loginUser.IsOnAccountCapable) 
                                            creditcardComponent = (
                                            <Label>
                                                Payroll Deduction and Credit Card Payment will be available at checkout.
                                            </Label>
                                        )

                                        return (
                                            <>
                                                {paymentComponent}
                                                {creditcardComponent}
                                            </>
                                        );
                                    })()}
                                </div>
                            </div>

                            {orders?.Orders?.length > 0 &&
                                <div className="card border-0 card--has-bg-color p-0 mb-5">
                                    <div className="card-header d-flex align-items-center border-0">
                                        <h2 className="card-title">Order History</h2>
                                        <Link to={`/${getLanguageCodeFromSession()}/orders-history`} className="ml-4 pl-4 card-header-link">
                                            {LocalizationLabelsArray.length > 0 ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, "View All Orders", "lbl_view_all_orders")
                                                :
                                                "View All Orders"
                                            }
                                        </Link>
                                    </div>

                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md order-history-divisor pb-5 pb-md-0">
                                                <div className="row">
                                                    <div className="col-12 label mb-3">
                                                        Most Recent Order
                                                    </div>
                                                    <div className="col-5 col-lg-4 order-history-labels font-weight-bold">Order Number:</div>
                                                    <div className="col-7 col-lg">
                                                        {orders?.Orders[0].OrderNumber}
                                                    </div>
                                                    <div className="w-100"></div>
                                                    <div className="col-5 col-lg-4 mb-4 order-history-labels font-weight-bold">Order Date:</div>
                                                    <div className="col-7 col-lg">{new Date(orders?.Orders[0].OrderDate).toLocaleString()}</div>
                                                    <div className="w-100"></div>
                                                    <div className="col-5 col-lg-4 order-history-labels font-weight-bold">Shipped To:</div>
                                                    <div data-private="lipsum" className="col-7 col-lg">
                                                        {orders?.Orders[0].ShippedTo}
                                                    </div>
                                                    <div className="w-100"></div>
                                                    {!isToggled&&<Fragment><div className="col-5 col-lg-4 order-history-labels font-weight-bold">Payment:</div>
                                                        {orders?.Orders[0].PaymentInstruments?.map(pi => {
                                                            return (
                                                                <div key={pi.PaymentMethodId} className='mb-2'>
                                                                    <div className="col-7 col-lg">{PAYMENT_INSTRUMENTS[pi.PaymentMethodId]}</div>
                                                                    {pi.PaymentMethodId === 'UAB_PAYROLL_DEDUCTION' &&
                                                                        <div className="col-7 col-lg">Max Deductions: {pi.PDMaxDeductions}</div>
                                                                    }
                                                                    <div className="col-7 col-lg">Amount: {formatCurrency(pi.Amount)}</div>
                                                                </div>
                                                            )
                                                        })}</Fragment>
                                                    }
                                                    <div className="w-100"></div>
                                                    <div className="col-5 col-lg-4 order-history-labels font-weight-bold">Order Status:</div>
                                                    <div className="col-7 col-lg">{orders?.Orders[0].OrderStatus}</div>
                                                    <div className="w-100"></div>
                                                    <div className="col-5 col-lg-4 order-history-labels font-weight-bold">Total Items:</div>
                                                    <div className="col-7 col-lg">{orders?.Orders[0].ItemCount}</div>
                                                    <div className="w-100"></div>
                                                    {!isToggled&&<Fragment>
                                                        <div className="col-5 col-lg-4 order-history-labels font-weight-bold">Order Total:</div>
                                                        <div className="col-7 col-lg">
                                                            ${orders?.Orders[0].OrderTotal}
                                                        </div></Fragment>
                                                    }
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-auto text-center mt-3 mt-lg-0 pl-lg-5">
                                                <Link to={`/${getLanguageCodeFromSession()}/order-detail/${orders?.Orders[0].OrderNumber}`} className="link-underline" id="lbl_ordrhis_detaillink">
                                                    {replaceLoclizationLabel(LocalizationLabelsArray, "Details", "lbl_ordrhis_detaillink")}
                                                </Link>
                                            </div>

                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default MyAccount;
